import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GitlabService } from './gitlab.service';

@Injectable({
  providedIn: 'root',
})
export class MarkdownService {

  constructor(
    public http: HttpClient,
    private giblabService: GitlabService,
  ) { }

  getContentByFolder(path: string): Promise<any> {
    const url = path.split('/');
    const productKey = url[2];
    url.slice(0, 1);
    let subdirectory = path.replace(`assets/root/${productKey}/`, '');
    subdirectory = subdirectory.replace('.md', '');
    return new Promise(
      (resol, rejec) => {
        this.giblabService.getDoc(productKey, subdirectory).then((res) => {
          resol(res);
        });
      },
    );
  }

}
