import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent implements OnInit {
  @Input() tagline = 'Título descriptivo';
  @Input() subTagline = null;

  constructor() { }

  ngOnInit() {
  }

}
