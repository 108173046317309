import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {

  info(value: any, ...rest: any[]): void {
    if (!environment.production) {
      // tslint:disable-next-line: no-console
      console.info(value, ...rest);
    }
  }

  log(msg: string): void {
    if (!environment.production) {
      console.log(msg);
    }
  }

  warn(value: any, ...rest: any[]): void {
    if (!environment.production) {
      console.warn(value, rest);
    }
  }

  error(msg: string, obj = {}): void {
    if (!environment.production) {
      console.error(msg, obj);
    }
  }
}
