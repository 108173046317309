const API_DOMAIN = 'https://e1-naranja-developers-bff-engineering-team.backendnaranja.com';

export const environment = {
  production: false,
  auth0: {
    domain: 'naranja-shops.auth0.com',
    clientID: 'tFg6ehFWchbdTZY6uLQDyPFp0Uyok6Cr',
    audience: 'https://naranja.com/rantyshops/api',
    scope: 'openid profile email write.self read:payment read:merchant read:payment_requests write:payment_requests write:payment_link read:nshops write:payment_callbacks noscope:dev', // tslint:disable-line
    urlRedirectAfterCallback: '/',
    urlRedirectUnauthorized: '/unauthorized',
    urlRedirectAfterLogin: '/callback',
    urlRedirectAfterLogout: '/logout',
  },
  seo: {
    gtmId: 'GTM-WJ2J3XV',
    gtmSecretKey: '',
    logging: true,
    canonicalDomain: 'https://e1-naranja-developers.cloudnaranja.com',
  },
  naranjaDevelopersBFF: {
    proxy: `${API_DOMAIN}/proxy/v2`,
    getSwagger: `${API_DOMAIN}/gitlab/swagger`,
    getSwaggerPrivate: `${API_DOMAIN}/gitlab/swagger/private`,
    getSitemap: `${API_DOMAIN}/gitlab/sitemap`,
    getDoc: `${API_DOMAIN}/gitlab/docs`,
    getProducts: `${API_DOMAIN}/contentful/products`, getProductsSandbox: `${API_DOMAIN}/contentful/products/sandbox`,
    version: `${API_DOMAIN}/version`,
  },
  // domainUrl: 'https://e1-naranja-developers.cloudnaranja.com',
  domainUrl: 'https://e1-naranja-developers.cloudnaranja.com',
  authentication: {
    login: 'https://g7f8zzkvc7.execute-api.us-east-1.amazonaws.com/dev/api/login',
  },
  msalAuth: {
    clientId: '182ac6d4-5173-4ac1-a8a3-fd9a43917122',
    authority: 'https://login.microsoftonline.com/1012c700-e42c-4aa6-9f4d-3dac6033b0f4',
    validateAuthority: true,
    redirectUri: 'https://e1-naranja-developers.cloudnaranja.com',
    postLogoutRedirectUri: 'https://e1-naranja-developers.cloudnaranja.com',
    navigateToLoginRequestUrl: true,
  },
};
