import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GitlabService {

  constructor(
    public http: HttpClient,
  ) { }

  async getSwagger(apiKey: string, productKey: string): Promise<any> {
    const token = localStorage.getItem('msal.idtoken');
    const headersHttp = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const paramsHttp = new HttpParams().set('api_name', apiKey).set('product_name', productKey);
    return await this.http.get(
      token ? environment.naranjaDevelopersBFF.getSwaggerPrivate : environment.naranjaDevelopersBFF.getSwagger,
      { headers: headersHttp, params: paramsHttp }).pipe(take(1)).toPromise();
  }

  async getSitemap(productKey: string, subdirectory?: string): Promise<any> {
    const token = localStorage.getItem('msal.idtoken');
    const headersHttp = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const paramsHttp = new HttpParams().set('product_name', productKey).set('subdirectory', subdirectory ? subdirectory : '');
    return await this.http.get(environment.naranjaDevelopersBFF.getSitemap, { headers: headersHttp, params: paramsHttp })
      .pipe(take(1)).toPromise();
  }

  async getDoc(productKey: string, subdirectory: string): Promise<any> {
    const token = localStorage.getItem('msal.idtoken');
    const headersHttp = new HttpHeaders().set('Authorization', `Bearer ${token}`).set('Content-Type', 'text/plain; charset=utf-8');
    const paramsHttp = new HttpParams().set('product_name', productKey).set('subdirectory', subdirectory);
    return await this.http.get(environment.naranjaDevelopersBFF.getDoc, { headers: headersHttp, params: paramsHttp, responseType: 'text' })
      .pipe(take(1)).toPromise();
  }

}
