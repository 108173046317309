import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EmbeddedViewRef,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationStart, RouterEvent, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { environment } from '../environments/environment';
import { SeoBaseService } from './core/utils/services/seo-base.service';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { SeoGtmService } from './core/utils/services/seo-gtm.service';
import { AuthenticationService } from './core/authentication/services/authentication.service';
import { NavigatorService } from './core/utils/services/navigator.service';
import { CallbackComponent } from './pages/callback/callback.component';
import { Subscription, Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  showHead = true;
  public isLoggedIn$ = this._authService.isLoggedIn$;
  public isBrowser = this._navigatorService.isBrowser;
  componentFactory;
  loginSubscription: Subscription;
  isLoading: boolean;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private appRef: ApplicationRef,
    private injector: Injector,
    private _seoGtm: SeoGtmService,
    private _seoBase: SeoBaseService,
    private _authService: AuthenticationService,
    private _navigatorService: NavigatorService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(ComponentFactoryResolver) private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this._seoBase.init();
    if (this._navigatorService.isBrowser) {
      this.showSpinner();
    }
  }

  private showSpinner() {
    this.loginSubscription = this._authService.loginInProgress$.subscribe((inProgress) => {
      if (inProgress) {
        this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(CallbackComponent).create(this.injector);
        this.appRef.attachView(this.componentFactory.hostView);
        const domElem = (this.componentFactory.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
      } else if (this.componentFactory) {
        this.appRef.detachView(this.componentFactory.hostView);
        this.componentFactory.destroy();
      }
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadScripts();
    }
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] === '/logout' || event['url'] === '/login') {
          this.showHead = false;
        } else {
          this.renderer.setStyle(this.document.body, 'overflow', 'auto');
          this.showHead = true;
        }
      }
    });

  }

  loadScripts() {
    this._seoGtm.loadGTMById(environment.seo.gtmId);
  }

  switchLanguage(lang: string): void {
    this.translate.use(lang);
  }

  ngOnDestroy() {
    this.showHead = true;
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }
}
