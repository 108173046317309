import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanLoad {

  constructor(private _authService: AuthenticationService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this._authService.isAuthenticated$.pipe(
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          this._authService.login();
          return false;
        }
        return true;
      }),
    );
  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this._authService.isAuthenticated$.pipe(
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          this._authService.login();
          return false;
        }
        return true;
      }),
    );
  }
}
