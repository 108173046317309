import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Component, Renderer2, Inject, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { AuthenticationParameters } from 'msal';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit, OnDestroy {
  initial: string;
  message: string;
  @Input() nickName: string;
  @Output() authUser = new EventEmitter();

  isCloseDropdown: boolean;
  isAuthenticated: boolean;

  private subscriptionToken: Subscription;
  private subscriptionLogin: Subscription;
  private subscriptionSso: Subscription;

  authParam: AuthenticationParameters = {
    scopes: ['user.read'],
  };

  constructor(
    private router: Router,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private authService: MsalService,
    private broadcastService: BroadcastService,
  ) {
    this.subscriptionToken = this.broadcastService.subscribe('msal:acquireTokenSuccess', (payload) => { });
    this.subscriptionLogin = this.broadcastService.subscribe('msal:loginSuccess', (payload) => { });
    this.subscriptionSso = this.broadcastService.subscribe('msal:ssoSuccess', (payload) => { });
  }

  ngOnInit() {

    this.isCloseDropdown = true;
    this.message = '¡Hola!';
    this.initial = 'H';

    this.broadcastService.subscribe('msal:acquireTokenSuccess', () => {
      this.isLoggedIn();
    });

    this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.isLoggedIn();
    });

    this.broadcastService.subscribe('msal:ssoSuccess', () => {
      this.isLoggedIn();
    });

  }

  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscriptionToken) {
      this.subscriptionToken.unsubscribe();
    }
    if (this.subscriptionLogin) {
      this.subscriptionLogin.unsubscribe();
    }
    if (this.subscriptionSso) {
      this.subscriptionSso.unsubscribe();
    }
  }

  isLoggedIn() {
    this.isAuthenticated = !!this.authService.getAccount();
    if (this.isAuthenticated) {
      this.nickName = this.authService.getAccount().name;
      this.setAvatar();
      this.setMessage();
      this.authUser.emit({ isAuthenticated: this.isAuthenticated, nickName: this.nickName });
    }
  }

  setAvatar() {
    if (this.nickName && this.nickName !== '') {
      this.initial = this.nickName.charAt(0).toLocaleUpperCase();
    }
  }

  setMessage() {
    if (this.nickName && this.nickName !== '') {
      this.message = `¡Hola, ${this.nickName}!`;
    }
  }

  logout() {
    this.authService.logout();
  }

  closeMenu() {
    this.isCloseDropdown = true;
    this.renderer.setStyle(this.document.body, 'overflow', 'auto');
  }

  openMenu() {
    if (this.isCloseDropdown) {
      this.isCloseDropdown = false;
      this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
    } else {
      this.renderer.setStyle(this.document.body, 'overflow', 'auto');
      this.isCloseDropdown = true;
    }
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup(this.authParam).then(() => {
        if (this.authService.getAccount()) {
          return this.authService.acquireTokenSilent({ scopes: ['user.read'] }).then(res => {
            localStorage.setItem('aadToken', res.accessToken);
            return { accessToken: res.accessToken };
          });
        }
      });
    }
  }

}
