import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
// ZUMO-CORE-COMPONENTS
import { NGZAccordionModule } from '@zumo/core/components/accordion';
import { NGZAvatarModule } from '@zumo/core/components/avatar';
import { NGZRadioButtonModule } from '@zumo/core/components/radiobuttons';
import { NGZAvatarPillModule } from '@zumo/core/components/avatar-pill';
import { NGZCardModule } from '@zumo/core/components/card';
import { NGZCheckboxsModule } from '@zumo/core/components/checkboxs';
import { NGZCheckboxGroupModule } from '@zumo/core/components/checkbox-group';
import { NGZCustomDialogModule } from '@zumo/core/components/custom-dialog';
import { NGZDialogModule } from '@zumo/core/components/dialog';
import { NGZListModule } from '@zumo/core/components/list';
import { NGZMessageModule } from '@zumo/core/components/message';
import { NGZSelectModule } from '@zumo/core/components/select';
import { NGZSwitchModule } from '@zumo/core/components/switch';
import { NGZTabsModule } from '@zumo/core/components/tabs';
import { NGZTextfieldModule } from '@zumo/core/components/textfield';
import { NGZSliderControlModule } from '@zumo/core/components/slider-control';
import { NGZTextfieldPredictiveModule } from '@zumo/core/components/textfield-predictive';
import { NGZModalModule } from '@zumo/core/components/modal';
import { NGZHeaderModule } from '@zumo/core/components/header';
import { NGZDrawerModule } from '@zumo/core/components/drawer';
import { NGZTagModule } from '@zumo/core/components/tag';
import { NGZNumericBadgeModule } from '@zumo/core/components/numeric-badge';
// ZUMO-CORE-OTHERS
import { ZUtilsModule } from '@zumo/core/utils';
import { ZFormModule } from '@zumo/core/forms';
import { ZServicesModule, NavigatorService } from '@zumo/core/services';
import { ZPipesModule } from '@zumo/core/pipes';
import { ZDirectiveModule } from '@zumo/core/directives';
import { ZPhoneModule } from '@zumo/core/phone';

@NgModule({
  imports: [
    CommonModule,
    ZFormModule,
    ZUtilsModule,
    ZServicesModule,
    ZPipesModule,
    ZDirectiveModule,
    NGZAccordionModule.forRoot(),
    NGZAvatarModule.forRoot(),
    NGZAvatarPillModule.forRoot(),
    NGZCardModule.forRoot(),
    NGZCheckboxsModule.forRoot(),
    NGZCheckboxGroupModule.forRoot(),
    NGZCustomDialogModule.forRoot(),
    NGZDialogModule.forRoot(),
    NGZListModule.forRoot(),
    NGZMessageModule.forRoot(),
    NGZRadioButtonModule.forRoot(),
    NGZSelectModule.forRoot(),
    NGZSwitchModule.forRoot(),
    NGZTabsModule.forRoot(),
    NGZTextfieldModule.forRoot(),
    NGZSliderControlModule.forRoot(),
    NGZTextfieldPredictiveModule.forRoot(),
    NGZModalModule.forRoot(),
    NGZHeaderModule.forRoot(),
    NGZDrawerModule.forRoot(),
    NGZTagModule.forRoot(),
    NGZNumericBadgeModule.forRoot(),
    ZPhoneModule.forRoot(),
  ],
  exports: [
    NGZAccordionModule,
    NGZAvatarModule,
    NGZAvatarPillModule,
    NGZCardModule,
    NGZCheckboxsModule,
    NGZCheckboxGroupModule,
    NGZCustomDialogModule,
    NGZDialogModule,
    NGZListModule,
    NGZMessageModule,
    NGZRadioButtonModule,
    NGZSelectModule,
    NGZSwitchModule,
    NGZTabsModule,
    NGZTextfieldModule,
    ZUtilsModule,
    NGZSliderControlModule,
    NGZTextfieldPredictiveModule,
    NGZModalModule,
    NGZHeaderModule,
    NGZDrawerModule,
    NGZTagModule,
    NGZNumericBadgeModule,
    ZPhoneModule,
    ZDirectiveModule,
  ],
  providers: [NavigatorService],
  declarations: [],
})
export class ZumoModule {
  constructor() {}
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ZumoModule,
    };
  }
}
