import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyUtils',
})
export class CurrencyUtilsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let amount = `${value} `;
    amount = parseFloat(amount.replace(/[^0-9\.]/g, '')).toString();
    amount = parseFloat(amount).toFixed(2);
    const amount_parts = amount.split('.');
    const regexp = /(\d+)(\d{3})/;
    while (regexp.test(amount_parts[0])) {
      amount_parts[0] = amount_parts[0].replace(regexp, '$1' + '.' + '$2');
    }
    amount = amount_parts.join(',');
    return `$${amount}`;
  }
}
