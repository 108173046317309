import { AuthenticationADGuard } from './pages/login/authentication-ad.guard';
import { CommonModule, registerLocaleData } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CoreModule } from './core/core.module';
import { RootStoreModule } from './root-store/root-store.module';
import { SharedModule } from './shared/shared.module';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { I18nService } from './core/i18n/i18n.service';
import { CallbackComponent } from './pages/callback/callback.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import es from '@angular/common/locales/es';
import { ZumoSnackbarService, ZumoSnackbarModule } from '@zumo/zumo-snackbar';

import { ClipboardModule } from 'ngx-clipboard';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

registerLocaleData(es, 'es');
// required for AOT compilation
export function customHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initLanguage(_i18nSvc: I18nService): Function {
  return (): Promise<any> => _i18nSvc.init();
}

export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']],
];

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    UnauthorizedComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    RootStoreModule,
    AppRoutingModule,
    ZumoSnackbarModule,
    ClipboardModule,
    MsalModule.forRoot(
      {
        auth: {
          clientId: environment.msalAuth.clientId,
          authority: environment.msalAuth.authority,
          validateAuthority: environment.msalAuth.validateAuthority,
          redirectUri: environment.msalAuth.redirectUri,
          postLogoutRedirectUri: environment.msalAuth.postLogoutRedirectUri,
          navigateToLoginRequestUrl: environment.msalAuth.navigateToLoginRequestUrl,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // set to true for IE 11
        },
      },
      {
        protectedResourceMap,
        popUp: !isIE,
        consentScopes: [
          'user.read',
          'openid',
          'profile',
        ],
        unprotectedResources: ['https://www.microsoft.com/en-us/'],
        extraQueryParameters: {},
      },
    ),
  ],
  providers: [
    // I18nService,
    // { provide: APP_INITIALIZER, useFactory: initLanguage, multi: true, deps: [I18nService] },
    { provide: LOCALE_ID, useValue: 'es' },
    AuthenticationADGuard,
    ZumoSnackbarService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
  ],
  entryComponents: [
    CallbackComponent,
    UnauthorizedComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
