import { DOCUMENT } from '@angular/common';
import { Component, Renderer2, Inject, OnInit, AfterViewChecked, RendererStyleFlags2 } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit, AfterViewChecked {
  title = 'Naranja Developers';
  onNavExpanded = false;
  topItems = [
    { name: 'kathu', spanish: 'Kathu', actionLink: 'https://khatu.naranja.com/' },
    { name: 'naranja', spanish: 'Naranja', actionLink: 'https://www.naranja.com/' },
  ];
  items = [
    { name: 'home', spanish: 'Home' },
    { name: 'products', spanish: 'Productos' },
    { name: 'sandbox', spanish: 'Sandbox' },
  ];

  mostrar = false;
  nickName = null;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private authService: MsalService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit(): void {
    this.nickName = localStorage.getItem('user');
  }

  ngAfterViewChecked(): void {
    this.mostrar = this.router.url.includes('products') ? true : false;
  }

  onClickOutside() {
    if (this.onNavExpanded) {
      this.onNavExpanded = false;
    }
  }

  onNavigate(actionLink: string) {
    if (actionLink.includes('http')) {
      window.open(actionLink, '_blank');
    } else {
      this.router.navigate([`${actionLink.toLowerCase()}`]);
      this.toggle();
    }
  }

  toggle() {
    if (!this.onNavExpanded) {
      this.onNavExpanded = !this.onNavExpanded;
      this.renderer.setStyle(this.document.body, 'overflow', 'hidden', RendererStyleFlags2.Important);
    } else {
      this.renderer.setStyle(this.document.body, 'overflow', 'auto');
      this.onNavExpanded = this.onNavExpanded;
    }
  }

  logout() {
    this.authService.logout();
  }

  isAuthenticated(event) {
    if (event.isAuthenticated) {
      this.nickName = event.nickName;
    }
  }

}
