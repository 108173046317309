import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ReadDirectoryService } from './read-directory.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private route: Router,
    private readDirectoryService: ReadDirectoryService,
  ) {}

  map: Map<string, string> = new Map<string, string>();

  async watchURL() {
    await this.route.events.pipe(take(1)).toPromise().then((e) => {
      if (e instanceof NavigationEnd) {
        const key = e.url.split('/').pop();

        this.findValue('asd');

        if (this.map.has(key)) {
        } else {
        }
      }
    });
  }

  findValue(ruta: string) {
    const path = this.route.url.slice(
      this.route.url.indexOf('/', 2) + 1,
      this.route.url.length,
    );

    const respuesta = this.findSitemap(path);
  }

  findSitemap(ruta: string): any {
    let respuesta = {};
    if (ruta.length > 0) {
      this.readDirectoryService.getSiteMapByPathV2(ruta).then(
        (resp) => {
          if (resp) {
            respuesta = resp;
          }
        },
      ).catch(
        (err) => {
          this.findSitemap(ruta.slice(0, ruta.lastIndexOf('/')));
        },
      );
    }
    return respuesta;
  }
}
