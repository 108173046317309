import { IZumoSnackbarData, ZumoSnackbarService } from '@zumo/zumo-snackbar';
import { DOCUMENT } from '@angular/common';
import { LoggerService } from 'src/app/core/utils/services/logger.service';
import {
  Component, OnInit, ViewEncapsulation, Input, AfterViewInit,
  ApplicationRef, AfterViewChecked, OnDestroy, Renderer2, ViewChild, ElementRef, Inject,
} from '@angular/core';
import { MarkdownService } from 'src/app/services/markdown.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { IContentProduct } from '../../../pages/products/store/content-product.reducer';
import { Store, select } from '@ngrx/store';
import * as fromSelectorContentProduct from '../../../pages/products/store/content-product.selectors';
import { Subscription, Subject } from 'rxjs';
import { ClipboardService } from 'ngx-clipboard';
import { MarkdownComponent } from 'ngx-markdown';

declare const Prism: any;

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ContentComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  dataPage: any = '';

  urlPath: string;

  selectItem: any;
  firstParam: any;

  contentURL: string;
  routeSubscribe: Subscription = null;
  storeSubscribe: Subscription = null;

  @Input() inputContent: string;

  styleArray: any;

  @ViewChild('markdown') markdown: MarkdownComponent;

  private unsubscribe$ = new Subject<void>();

  findButton = false;
  eventButton = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private markdownService: MarkdownService,
    private store: Store<IContentProduct>,
    private rendered: Renderer2,
    private _logger: LoggerService,
    private _clipboardService: ClipboardService,
    private el: ElementRef,
    private renderer: Renderer2,
    private _elementRef: ElementRef,
    @Inject(DOCUMENT) document,
    private snackService: ZumoSnackbarService,
  ) {
    this.urlPath = this.router.url;

  }

  ngAfterViewInit(): void {

  }

  copyJSON(index) {
    const collectionHTML: any = Array.from(this.markdown.element.nativeElement.children);

    const string: any = collectionHTML[index].innerText;
    this._clipboardService.copyFromContent(string);
    this.displaySnackbarOnlyText('Copiaste la información');
  }

  displaySnackbarOnlyText(textSnack: string) {
    const dataSnackbar: IZumoSnackbarData = {
      duration: 5,
      text: textSnack,
      hasDuration: true,
    };

    this.snackService.showSnackbar(dataSnackbar);
  }

  ngOnInit() {
    this.setStylesCard();
    // Prism curl
    Prism.languages.curl = {
      curl: /\bcurl\b/,
      url: /https?:[a-zA-Z0-9:.?=\/\-_{}]*/,
      parameter: {
        pattern: /[A-Za-z0-9\[\]-_]+ *(?=[=])/,
      },
      value: [{
        pattern: /([=])([A-Za-z0-9-_.]*)/,
        lookbehind: true,
      }, {
        pattern: /(["'])(\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\1/,
      }, {
        pattern: /(\-u )([A-Za-z0-9-_.{}]*)/,
        lookbehind: true,
      }],
      option: / *-[a-zA-Z]*\b/,
    };
    this.router.events.forEach(async (event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          await this.markdownService.getContentByFolder(`assets/root/${event.url.replace('/products', '')}.md`).then(
            resForLocalStore => {
              this.dataPage = resForLocalStore;
              this.findButton = false;
            },
          );
        }
      }
    });
    this.routeSubscribe = this.route.params.subscribe(
      params => {
        this.firstParam = params['menuName'];
        this.selectItem = params['itemSubmenu'];

        if (this.inputContent !== undefined && this.inputContent !== null) {
          this.markdownService.getContentByFolder(this.inputContent).then(
            res => {
              this.dataPage = res;
              this.findButton = false;
            },
          );
        } else {
          this.storeSubscribe = this.store
            .pipe(select(fromSelectorContentProduct.selectData))
            .subscribe(
              res => {

                if (res && res !== undefined && res.contentURL !== undefined) {
                  this.markdownService.getContentByFolder(res.contentURL).then(
                    resMark => {
                      this.dataPage = resMark;
                      this.findButton = false;
                    },
                  );
                } else {
                  this.contentURL = localStorage.getItem('contentURL');
                  const url: string = this.router.url.replace('/products/', '');
                  const lastParamUrl = url.slice(url.lastIndexOf('/'), url.length);
                  if (this.contentURL && this.contentURL.length > 3) {
                    if (this.contentURL.search(url) === -1) {
                      if (this.selectItem) {
                        this.markdownService.getContentByFolder(`assets/root/${url}.md`).then(
                          resForLocalStore => {
                            this.dataPage = resForLocalStore;
                            this.findButton = false;
                          },
                        );
                      } else {
                        this.markdownService.getContentByFolder(`assets/root/${url}${lastParamUrl}.md`).then(
                          resForLocalStore => {
                            this.dataPage = resForLocalStore;
                            this.findButton = false;
                          },
                        );
                      }
                    } else {
                      this.markdownService.getContentByFolder(this.contentURL).then(
                        resForLocalStore => {
                          this.dataPage = resForLocalStore;
                          this.findButton = false;
                        },
                      );
                    }
                  } else {
                    if (this.selectItem) {
                      this.markdownService.getContentByFolder(`assets/root/${url}.md`).then(
                        resForLocalStore => {
                          this.dataPage = resForLocalStore;
                          this.findButton = false;
                        },
                      );
                    } else {
                      this.markdownService.getContentByFolder(`assets/root/${url}${lastParamUrl}.md`).then(
                        resForLocalStore => {
                          this.dataPage = resForLocalStore;
                          this.findButton = false;
                        },
                      );
                    }
                  }
                }
              },
              err => {
                this._logger.error(err);
              },
            );
        }
      },
    );
  }

  onReady() {
    if (this.markdown !== undefined) {
      const collectionHTML: any = Array.from(this.markdown.element.nativeElement.children);

      let indexButton = null;

      for (let index = 0; index < collectionHTML.length; index = index + 1) {
        if (collectionHTML[index].className === 'code-toolbar') {
          if (collectionHTML[index].firstChild
            && collectionHTML[index].firstChild.className.includes('language-json')
            || collectionHTML[index].firstChild.className.includes('language-curl')
          ) {
            collectionHTML[index - 1].insertAdjacentHTML(
              'beforeend', `<div id="btnCopy${index}" name="Copiar" class="btn-copy" onClick="copyJSON()">
                <div class="btn-json">
                <div class="icon">
                  <p class="icon-copy size-24"></p>
                </div>
                </div>
              </div>`);
            indexButton = index - 1;
            for (let j = 0; j < collectionHTML[indexButton].children.length; j = j + 1) {
              if (collectionHTML[indexButton].children[j].className.includes('btn-copy')) {
                collectionHTML[indexButton].children[j].addEventListener('click', (event) => {
                  const indexCopy = event.currentTarget.id.replace('btnCopy', '');
                  this.copyJSON(indexCopy);
                });
              }
            }
          }
        }
      }
    }
  }

  setStylesCard() {
    this.styleArray = {
      padding: '32px',
    };
  }

  ngAfterViewChecked() {
    if (this.routeSubscribe) {
      this.routeSubscribe.unsubscribe();
    }

  }

  ngOnDestroy(): void {
    if (this.routeSubscribe) {
      this.routeSubscribe.unsubscribe();
    }
    if (this.storeSubscribe) {
      this.storeSubscribe.unsubscribe();
    }
  }

  copy(url: string) {
    const server = url.replace('Servidor: ', '');
    this._clipboardService.copyFromContent(server);

  }

}
