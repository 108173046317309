import { LoggerService } from 'src/app/core/utils/services/logger.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  links: any[];
  watchURL: any;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private _logger: LoggerService,
  ) {
    // this.watchURL = this.navigationService.watchURL();
  }

  ngOnInit() {
    this.links = this.router.url.split('/');
    this.links = this.links.slice(1, this.links.length);
    this.listenRouting();
  }

  navigateTo(index: number, link: string) {
    this._logger.warn(link);
    const newUrl = this.links.slice(0, index + 1);
    this.router.navigate([...newUrl]);
  }

  listenRouting() {
    this.router.events
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.links = this.router.url.split('/');
        this.links = this.links.slice(1, this.links.length);
      }
    });
  }

/*   async listenRouting() {
    await this.router.events
    .pipe(take(1)).toPromise().then(e => {
      if (e instanceof NavigationEnd) {
        this.links = this.router.url.split('/');
        this.links = this.links.slice(1, this.links.length);
      }
    });
  } */

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
