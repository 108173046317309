import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './pages/callback/callback.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { MsalGuard } from '@azure/msal-angular';

const commonsRoutes: Routes = [
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'home',
    loadChildren: './pages/home/home.module#HomeModule',
    data: {
      title: 'Naranja Developers - Home',
      description: 'una description',
      canonicalUrl: '/home',
      robots: 'follow',
    },
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'not-found',
    loadChildren: './pages/not-found/not-found.module#NotFoundModule',
    // canLoad: [AuthenticationGuard],
  },
  {
    path: 'products',
    loadChildren: './pages/products/products.module#ProductsModule',
    data: {
      title: 'Naranja Developers - Products',
    },
    canActivate: [MsalGuard],
  },
  {
    path: 'sandbox',
    loadChildren: './pages/test-api/test-api.module#TestApiModule',
    data: {
      title: 'Naranja Developers - Sandbox',
    },
    canActivate: [MsalGuard],
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(commonsRoutes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
