import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
  ],
  exports: [
    BreadcrumbComponent,
    TranslateModule,
  ],
  providers: [
    NavigationService,
  ],
})
export class BreadcrumbModule { }
