import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LazysizesDirective } from './directives/lazysizes/lazysizes.directive';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { UserMenuComponent } from '../features/user-menu/containers/user-menu/user-menu.component';
import { CurrencyUtilsPipe } from './pipes/currency-utils.pipe';
import { ZumoTooltipModule } from '@zumo/zumo-tooltip';
import { ZumoButtonModule } from '@zumo/zumo-button';
import { ZumoDropdownModule } from '@zumo/zumo-dropdown';
import { ZumoIconContainerModule } from '@zumo/zumo-icon-container';
import { MarkdownModule } from 'ngx-markdown';
import { ContentComponent } from './components/content/content.component';
import { BreadcrumbModule } from '../features/breadcrumb/breadcrumb.module';
import { ZumoOverlayComponent, ZumoOverlayModule } from '@zumo/zumo-overlay';
import { ZumoModalDialogModule, ZumoModalDialogService } from '@zumo/zumo-modal-dialog';
import { ZumoCardModule } from '@zumo/zumo-card';
import { ZumoModule } from './zumo.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeroComponent } from '../features/hero/hero.component';
import { ZumoSpinnerModule } from '@zumo/zumo-spinner';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    RouterModule,
    ZumoTooltipModule,
    ZumoButtonModule,
    ZumoDropdownModule,
    ZumoOverlayModule,
    ZumoIconContainerModule,
    MarkdownModule.forRoot(),
    BreadcrumbModule,
    ZumoSpinnerModule,
    ZumoModalDialogModule,
    ZumoModule,
    ZumoCardModule,
  ],
  exports: [
    TranslateModule,
    LazysizesDirective,
    HeaderComponent,
    FooterComponent,
    CurrencyUtilsPipe,
    ZumoTooltipModule,
    ZumoButtonModule,
    ZumoOverlayModule,
    ZumoIconContainerModule,
    ZumoDropdownModule,
    ContentComponent,
    BreadcrumbModule,
    ZumoSpinnerModule,
    ZumoModalDialogModule,
    ZumoModule,
    ZumoCardModule,
    HeroComponent,
  ],
  declarations: [
    LazysizesDirective,
    HeaderComponent,
    FooterComponent,
    UserMenuComponent,
    CurrencyUtilsPipe,
    ContentComponent,
    HeroComponent,
  ],
  entryComponents: [
    ZumoOverlayComponent,
  ],
  providers: [
    ZumoModalDialogService,
  ],
})
export class SharedModule { }
