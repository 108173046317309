import { LoggerService } from 'src/app/core/utils/services/logger.service';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GitlabService } from './gitlab.service';

@Injectable({
  providedIn: 'root',
})
export class ReadDirectoryService {

  constructor(
    public http: HttpClient,
    private _logger: LoggerService,
    private giblabService: GitlabService,
  ) { }

  // /gitlab/sitemap?product_name=ndelivery&subdirectory=apis

  getMenuByPath(product: string): Promise<any> {
    return new Promise(
      (resol, rejec) => {
        this.giblabService.getSitemap(product).then((res) => {
          resol(this.buildMenu(res));
        });
      },
    );
  }

  getSiteMapByPath(productKey: string, subdirectory?: string): Promise<any> {
    return new Promise(
      (resol, rejec) => {
        this.giblabService.getSitemap(productKey, subdirectory).then((res) => {
          resol(res);
        });
      },
    );

  }

  getSiteMapByPathV2(path): Promise<any> {
    return new Promise(
      (resol, rejec) => {
        this.giblabService.getSitemap(path).then((res) => {
          resol(res);
        });
      },
    );
  }

  buildMenu(aProduct) {
    const listSideNav = [aProduct.content.length];
    for (let index = 0; index < aProduct.content.length; index = index + 1) {
      listSideNav[index] = {};
      if (index === 0) {
        listSideNav[index]['active'] = true;
      } else {
        listSideNav[index]['active'] = false;
      }
      listSideNav[index]['nameMenu'] = aProduct.content[index].name;
      listSideNav[index]['key'] = aProduct.content[index].key;
      listSideNav[index]['actionLink'] = aProduct.content[index].key;
      listSideNav[index]['url'] = aProduct.content[index].url;
      listSideNav[index]['accesible'] = aProduct.content[index].accesible;
      if (aProduct.content[index].content.length > 0) {
        listSideNav[index]['items'] = [aProduct.content[index].content.length];
        for (let jindex = 0; jindex < aProduct.content[index].content.length; jindex = jindex + 1) {
          listSideNav[index]['items'][jindex] = {};
          listSideNav[index]['items'][jindex]['name'] = aProduct.content[index].content[jindex].name;
          listSideNav[index]['items'][jindex]['key'] = aProduct.content[index].content[jindex].key;
          listSideNav[index]['items'][jindex]['actionLink'] = aProduct.content[index].content[jindex].key;
          listSideNav[index]['items'][jindex]['url'] = aProduct.content[index].content[jindex].url;
          listSideNav[index]['items'][jindex]['accesible'] = aProduct.content[index].content[jindex].accesible;
        }
      }
    }
    return listSideNav;
  }

  buildURL(path: any): string {
    let aux: any;
    if (Array.isArray(path)) {
      aux = path;
    } else {
      aux = path.split('/');
    }
    let url = '';
    for (let index = 0; index < aux.length; index = index + 1) {

      if (index === (aux.length - 1)) {
        url = url + aux[index]; // tslint:disable-line
      } else if (index > 1) {
        url = url + aux[index] + '/'; // tslint:disable-line
      }

    }
    return url;
  }

  // DEPRECATED
  getSwaggerByUrl(url: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return new Promise((resolve) => {
      this.http.get(url, { headers, responseType: 'text' }).subscribe(
        res => {
          resolve(JSON.parse(res));
        },
      );
    },
    );
  }

  getDataAuth0BySwagger(url: string) {
    const token = localStorage.getItem('msal.idtoken');
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8').set('Authorization', `Bearer ${token}`);
    return new Promise((resolve) => {
      this.http.get(url, { headers, responseType: 'text' }).subscribe(
        res => {
          resolve(JSON.parse(res).components.securitySchemes);
        },
      );
    },
    );
  }

  getExamplesEndpointBySwagger(url: string, operationId: string) {
    const token = localStorage.getItem('msal.idtoken');
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8').set('Authorization', `Bearer ${token}`);
    return new Promise((resolve) => {
      this.http.get(url, { headers, responseType: 'text' }).subscribe(
        res => {
          resolve(this.getEndpointByOperationId(JSON.parse(res).paths, operationId));
        },
      );
    },
    );
  }

  getEndpointByOperationId(paths, operationId): any[] {
    let examples;
    Object.keys(paths).find(item => {
      if (paths[item].get !== undefined) {
        if (paths[item].get.operationId === operationId) {
          examples = paths[item].get['x-examples'];
          return true;
        }
      }
      if (paths[item].post !== undefined) {
        if (paths[item].post.operationId === operationId) {
          examples = paths[item].post['x-examples'];
          return true;
        }
      }
      if (paths[item].put !== undefined) {
        if (paths[item].put.operationId === operationId) {
          examples = paths[item].put['x-examples'];
          return true;
        }
      }
      if (paths[item].delete !== undefined) {
        if (paths[item].delete.operationId === operationId) {
          examples = paths[item].delete['x-examples'];
          return true;
        }
      }
    });
    return examples;
  }
}
