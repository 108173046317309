import { ZumoSnackbarService, IZumoSnackbarData } from '@zumo/zumo-snackbar';
import { Injectable, OnInit } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Observable, Subscription, Subject } from 'rxjs';

@Injectable()
export class AuthenticationADGuard implements CanActivate, CanLoad {

  public eventSubject: Subject<any> = new Subject();
  public eventSubscription: Subscription;

  constructor(
    private snackService: ZumoSnackbarService,
    private router: Router,
  ) {
    this.eventSubscription = this.eventSubject.asObservable().subscribe(
      (event) => {
        if (event) {
          this.eventRouteLogin();
        }
      });
  }

  eventRouteLogin() {
    this.router.navigate(['/login']);
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return false;
  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    const token = localStorage.getItem('msal.idtoken');
    const authenticated = true;
    if (token && token.length > 3) {
      return true;
    }
    this.displaySnackbar();
    this.router.navigate(['/login']);
    return false;
  }

  displaySnackbar() {
    const dataSnackbar: IZumoSnackbarData = {
      duration: 10,
      text: 'Inicie sesión para ver mas información',
      hasDuration: true,
      eventName: 'Ingresar',
    };
    this.snackService.showSnackbar(dataSnackbar);
  }
}
